export const DesktopHeader = () => {
  return (
    <div className="bg-black h-[954px] pt-8 relative px-[100px]">
      <div className="flex justify-between">
        <div className="mr-6">
          <img src="/img/LogoWhite.svg" alt="Logo" className="w-24 h-full" />
        </div>
        <p className="text-white text-[42px] font-boldTitle">БУЛІНЬ</p>
        <p className="text-white text-[42px] font-boldTitle">
          12.05.2000 - 26.05.2022
        </p>
      </div>
      <div className="flex">
        <div className="relative">
          <p className="text-mint mr-3 text-9xl font-boldTitle mt-36">
            МАКСИМ
            <br />
            КВАПИШ
          </p>
          <p className="text-white text-[42px] whitespace-normal mt-28">
            Присутній!
          </p>
        </div>
        <img
          className="w-[701px] h-[783px] absolute bottom-0 right-28"
          src="/img/HeaderMain.png"
          alt="Header Image"
        />
      </div>
    </div>
  );
};
