import React, { useRef, useState } from "react";
import { text } from "../../text/text";
import { CSSTransition } from "react-transition-group";

export const StasStory = () => {
  const [showFullText, setShowFullText] = useState(false);
  const toggleButtonRef = useRef(null);

  const toggleText = () => {
    setShowFullText(!showFullText);
    if (toggleButtonRef.current) {
      toggleButtonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const stasStoryParagraphs = text.stasStory.split("\n");

  return (
    <div
      className="p-5 bg-black border-b-2 border-white lg:px-48 lg:py-10"
      ref={toggleButtonRef}
    >
      <CSSTransition in={showFullText} timeout={300} classNames="my-node">
        {showFullText ? (
          <div className="flex flex-col ">
            <p className="text-white text-lg  ml-3 font-boldTitle lg:text-3xl">
              «...ВІН ЖИВ ЦІЄЮ ІДЕЄЮ І ПОСТІЙНО РОЗПОВІДАВ ПРО ТЕ ЯК БУЛО
              РАНІШЕ, ПРО ТЕ ЯК Є ЗАРАЗ І ПРО ТЕ ЯК ПОВИННО БУТИ…»
            </p>
            <img
              className="h-full self-center my-4 w-[450px] hidden lg:block"
              src="/img/stasStory/StasMax.jpg"
              alt="StasMax"
            />
            <img
              className="w-full h-full self-center my-4 lg:hidden"
              src="/img/stasStory/StasMax_mobile.jpg"
              alt="StasMax"
            />
            {stasStoryParagraphs.map((paragraph, index) => (
              <p
                key={index}
                className="text-white text-justify text-base mb-2 lg:text-xl lg:mb-4"
              >
                {paragraph}
              </p>
            ))}

            <div className="flex justify-between content-center mt-4">
              <p className="text-white text-lg font-boldTitle">- ДРУГ СТАС</p>
              <div
                className="flex items-center cursor-pointer "
                onClick={toggleText}
              >
                <div className="w-3 h-3 mr-4">
                  <img src="/img/Vector.svg" alt="Expand" />
                </div>

                <p className="text-white text-lg font-boldTitle">ЗГОРНУТИ</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex">
            <img
              className="w-[94px] h-[151px] lg:w-[208px] lg:h-full"
              src="/img/stasStory/Main.jpg"
              alt="Main"
            />

            <div className="flex flex-col justify-between ml-6">
              <p className="text-white text-lg font-boldTitle lg:text-3xl">
                «...ВІН ЖИВ ЦІЄЮ ІДЕЄЮ І ПОСТІЙНО РОЗПОВІДАВ ПРО ТЕ ЯК БУЛО
                РАНІШЕ, ПРО ТЕ ЯК Є ЗАРАЗ І ПРО ТЕ ЯК ПОВИННО БУТИ…»
              </p>

              <div
                className="flex items-center cursor-pointer mt-3"
                onClick={toggleText}
              >
                <div className="w-3 h-3 mr-4">
                  <img src="/img/Vector.svg" alt="Expand" />
                </div>
                <p className="text-white text-lg">РОЗГОРНУТИ РОЗПОВІДЬ СТАСА</p>
              </div>
            </div>
          </div>
        )}
      </CSSTransition>
    </div>
  );
};
