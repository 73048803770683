import { AndriyStory } from "./components/AndriyStory";
import { Biography } from "./components/Biography";
import { DanielStory } from "./components/DanielStory";
import { DimaStory } from "./components/DimaStory";
import { Footer } from "./components/Footer";
import { Gallery } from "./components/Gallery";
import { Header } from "./components/Header";
import { Immortalization } from "./components/Immortalization";
import { KyrilStory } from "./components/KyrilStory";
import { LastFight } from "./components/LastFight";
import { Mural } from "./components/Mural";
import { NadyaStory } from "./components/NadyaStory";
import { RomaStory } from "./components/RomaStory";
import { RuslanStory } from "./components/RuslanStory";
import { StasStory } from "./components/StasStory";
import { Stories } from "./components/Stories";
import { TaniaStory } from "./components/TaniaStory";
import { YanStory } from "./components/YanStory";

function App() {
  return (
    <div className="App">
      <div className="flex flex-col">
        <Header />
        <Biography />
        <LastFight />
        <Stories />
        <TaniaStory />
        <KyrilStory />
        <DimaStory />
        <DanielStory />
        <RomaStory />
        <RuslanStory />
        <AndriyStory />
        <StasStory />
        <YanStory />
        <NadyaStory />
        <Mural />
        <Immortalization />
        <Gallery />
        <Footer />
      </div>
    </div>
  );
}

export default App;
