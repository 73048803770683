import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export const Gallery = () => {
  return (
    <div className="p-5 bg-black lg:p-48">
      <div className="flex mb-6 lg:mb-12">
        <img
          className="lg:w-[66px] lg:h-[66px] lg:mt-1 h-8"
          src="/img/gallery.png"
          alt="gallery"
        />
        <p className="text-mint ml-3 text-4xl font-boldTitle lg:text-8xl">
          СПОГАДИ
        </p>
      </div>
      <Carousel infiniteLoop showStatus={false} showIndicators={false}>
        {Array.from({ length: 30 }, (_, index) => (
          <div key={index}>
            <img
              src={`/img/gallery/gallery${index + 1}.jpg`}
              alt={`gallery${index + 1}`}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};
