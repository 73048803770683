import React from "react";
import { MobileHeader } from "./Header.mobile";
import { DesktopHeader } from "./Header.desktop";

export const Header = () => {
  return (
    <div>
      <div className="lg:hidden">
        <MobileHeader />
      </div>
      <div className="hidden lg:block">
        <DesktopHeader />
      </div>
    </div>
  );
};
