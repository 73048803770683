import React, { useRef, useState } from "react";
import { text } from "../../text/text";
import { CSSTransition } from "react-transition-group";

export const Biography = () => {
  const [showFullText, setShowFullText] = useState(false);
  const toggleButtonRef = useRef(null);

  const toggleText = () => {
    setShowFullText(!showFullText);
    if (toggleButtonRef.current) {
      toggleButtonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const biographyParagraphs = text.biographyMobile.split("\n");

  return (
    <div className="p-5 bg-greenDark lg:p-48" ref={toggleButtonRef}>
      <div className="flex mb-6 content-center lg:mb-12">
        <img
          className="lg:w-[66px] lg:h-[66px] lg:mt-1 h-8"
          src="/img/Biography.svg"
          alt="Biography"
        />
        <p className="text-mint ml-3 text-4xl font-boldTitle lg:text-8xl">
          БІОГРАФІЯ
        </p>
      </div>
      <CSSTransition in={showFullText} timeout={300} classNames="my-node">
        {showFullText ? (
          <div>
            {biographyParagraphs.map((paragraph, index) => (
              <p
                key={index}
                className="text-white text-justify text-base mb-4 lg:text-xl lg:mb-8"
              >
                {paragraph}
              </p>
            ))}
          </div>
        ) : (
          <div>
            <p className="text-white text-justify text-base mb-4 lg:text-xl lg:mb-8">
              {biographyParagraphs[0]}
            </p>
            <p className="text-white text-justify text-base mb-4 lg:text-xl lg:mb-8">
              {biographyParagraphs[2]}..
            </p>
          </div>
        )}
      </CSSTransition>
      <div
        className="flex justify-end items-center cursor-pointer"
        onClick={toggleText}
      >
        <div className="w-3 h-3 mr-4">
          <img src="/img/Vector.svg" alt="Expand" />
        </div>
        <p className="text-white text-lg font-boldTitle">
          {showFullText ? "ЗГОРНУТИ" : "ЧИТАТИ ДАЛІ"}
        </p>
      </div>
    </div>
  );
};
