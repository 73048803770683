import React from "react";

export const Stories = () => (
  <div className="p-5 bg-white lg:px-32">
    <div className="flex mb-6">
      <img
        className="lg:w-[66px] lg:h-[66px] lg:mt-1 h-8"
        src="/img/Stories.svg"
        alt="Biography"
      />
      <p className="text-greenHeader ml-3 text-4xl font-boldTitle lg:text-8xl">
        ДРУЗІ ПРО ГЕРОЯ
      </p>
    </div>
  </div>
);
