import React, { useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { text } from "../../text/text";

export const TaniaStory = () => {
  const [showFullText, setShowFullText] = useState(false);
  const toggleButtonRef = useRef(null);

  const toggleText = () => {
    setShowFullText(!showFullText);
    if (toggleButtonRef.current) {
      toggleButtonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const taniaStoryParagraphs = text.taniaStory.split("\n");
  const taniaStory2Paragraphs = text.taniaStory2.split("\n");

  return (
    <div
      className="p-5 bg-black border-b-2 border-white lg:px-48 lg:py-10"
      ref={toggleButtonRef}
    >
      <CSSTransition in={showFullText} timeout={300} classNames="my-node">
        {showFullText ? (
          <div className="flex flex-col ">
            <p className="text-white text-lg font-boldTitle lg:text-3xl">
              «ВОЇН, ЯКИЙ МАВ АВТОРИТЕТ, ЗДОБУТИЙ ЗАВДЯКИ БЕЗДОГАННОМУ
              ВІЙСЬКОВОМУ ВИШКОЛУ І ПРИНАЛЕЖНОСТІ ДО ОБРАНИХ»
            </p>
            <img
              className="w-full h-full self-center my-4 lg:w-[700px]"
              src="/img/taniaStory/TaniaMax.jpg"
              alt="TaniaMax"
            />
            {taniaStoryParagraphs.map((paragraph, index) => (
              <p
                key={index}
                className="text-white text-justify text-base mb-2 lg:text-xl lg:mb-4"
              >
                {paragraph}
              </p>
            ))}
            <img
              className="w-full h-full self-center my-4 lg:w-[700px]"
              src="/img/taniaStory/TaniaMax2.jpg"
              alt="TaniaMax"
            />
            {taniaStory2Paragraphs.map((paragraph, index) => (
              <p
                key={index}
                className="text-white text-justify text-base mb-2 lg:text-xl lg:mb-4"
              >
                {paragraph}
              </p>
            ))}
            <img
              className="w-full h-full self-center my-4 lg:w-[700px]"
              src="/img/taniaStory/TaniaMax3.jpg"
              alt="TaniaMax"
            />

            <div className="flex justify-between content-center mt-4">
              <p className="text-white text-lg font-boldTitle">
                - ДРУЖИНА ТАНЯ
              </p>
              <div
                className="flex items-center cursor-pointer "
                onClick={toggleText}
              >
                <div className="w-3 h-3 mr-4">
                  <img src="/img/Vector.svg" alt="Expand" />
                </div>

                <p className="text-white text-lg font-boldTitle">ЗГОРНУТИ</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex">
            <img
              className="w-[94px] h-[151px] lg:w-[208px] lg:h-full"
              src="/img/taniaStory/Main.jpg"
              alt="Main"
            />

            <div className="flex flex-col justify-between ml-6">
              <p className="text-white text-lg font-boldTitle lg:text-3xl">
                «ВОЇН, ЯКИЙ МАВ АВТОРИТЕТ, ЗДОБУТИЙ ЗАВДЯКИ БЕЗДОГАННОМУ
                ВІЙСЬКОВОМУ ВИШКОЛУ І ПРИНАЛЕЖНОСТІ ДО ОБРАНИХ»
              </p>

              <div
                className="flex items-center cursor-pointer mt-3"
                onClick={toggleText}
              >
                <div className="w-3 h-3 mr-4">
                  <img src="/img/Vector.svg" alt="Expand" />
                </div>
                <p className="text-white text-lg">РОЗГОРНУТИ РОЗПОВІДЬ ТАНІ</p>
              </div>
            </div>
          </div>
        )}
      </CSSTransition>
    </div>
  );
};
