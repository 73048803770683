export const MobileHeader = () => {
  return (
    <div className="bg-black px-8 ">
      <div className="flex py-12 justify-left">
        <div className="w-11 h-11  mr-6">
          <img src="/img/LogoWhite.svg" />
        </div>

        <div className="text-white text-base font-extrabold flex-wrap">
          <p>БУЛІНЬ</p>
          <p>12.05.2000 - 26.05.2022</p>
        </div>
      </div>
      <div className="flex flex-wrap">
        <p className="text-mint mr-3 text-5xl font-boldTitle">
          МАКСИМ
          <br />
          КВАПИШ
        </p>
        <p className="text-white text-base whitespace-normal">Присутній!</p>
      </div>
      <div>
        <img src="/img/HeaderMain.png" />
      </div>
    </div>
  );
};
