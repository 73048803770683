import React from "react";
import { text } from "../../text/text";
import YouTube from "react-youtube";

export const Mural = () => {
  const muralParagraphs = text.mural.split("\n");

  const videoOptions = {
    height: "585px",
    width: "960px",
    playerVars: {
      autoplay: 0,
    },
  };
  const videoOptionsMobile = {
    width: "100%",
    height: "220px",
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <div className="p-5 bg-black lg:p-48 border-b-2 border-white">
      <div className="flex my-6">
        <img
          className="lg:w-[66px] lg:h-[66px] lg:mt-1 h-8"
          src="/img/Mural.svg"
          alt="Mural"
        />
        <p className="text-mint ml-3 text-4xl font-boldTitle lg:text-8xl">
          МУРАЛ ПАМ’ЯТІ
        </p>
      </div>

      <div className="hidden lg:flex justify-center my-8">
        <YouTube videoId="RmHlsVKZGRE" opts={videoOptions} />
      </div>
      <div className="my-4 lg:hidden">
        <YouTube videoId="RmHlsVKZGRE" opts={videoOptionsMobile} />
      </div>

      {muralParagraphs.map((paragraph, index) => (
        <p
          key={index}
          className="text-white text-justify text-base mb-4 lg:text-xl lg:mb-4"
        >
          {paragraph}
        </p>
      ))}
      <p className="text-white text-lg font-boldTitle text-right">
        - ДРУЖИНА МАКСИМА
      </p>
    </div>
  );
};
