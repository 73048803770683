import React, { useRef, useState } from "react";
import { text } from "../../text/text";
import { CSSTransition } from "react-transition-group";

export const LastFight = () => {
  const [showFullText, setShowFullText] = useState(false);
  const toggleButtonRef = useRef(null);

  const toggleText = () => {
    setShowFullText(!showFullText);
    if (toggleButtonRef.current) {
      toggleButtonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const lastFightSecondParagraphs = text.lastFightSecond.split("\n");
  const lastFightThirdParagraphs = text.lastFightThird.split("\n");

  return (
    <div className="p-5 bg-white lg:p-48" ref={toggleButtonRef}>
      <div className="flex mb-6 lg:mb-12">
        <img
          className="lg:w-[66px] lg:h-[66px] lg:mt-1 h-8"
          src="/img/lastFight/LastFight.svg"
          alt="Biography"
        />
        <p className="text-greenHeader ml-3 text-4xl font-boldTitle lg:text-8xl">
          ОСТАННІЙ БІЙ БУЛІНЯ
        </p>
      </div>
      <CSSTransition in={showFullText} timeout={300} classNames="my-node">
        {showFullText ? (
          <div className="flex flex-col">
            <div>
              <div className="flex flex-col lg:float-left">
                <img
                  className="w-full h-full self-center lg:mb-3 ml-3 lg:ml-0 lg:mr-5 lg:w-80"
                  src="/img/lastFight/MaxBig.jpg"
                />
                <img
                  src="/img/lastFight/BTR.jpg"
                  className="mb-4 lg:w-80 lg:self-center hidden lg:block lg:mb-3 ml-3 lg:ml-0 lg:mr-5"
                />
              </div>
              <p className="text-justify text-base my-4 lg:text-xl lg:mb-4">
                {text.lastFightFirst}
              </p>

              <img
                src="/img/lastFight/BTR.jpg"
                className="mb-4 lg:w-80 lg:self-center lg:hidden"
              />

              {lastFightSecondParagraphs.map((paragraph, index) => (
                <p
                  key={index}
                  className="text-justify text-base mb-2 lg:text-xl lg:mb-4"
                >
                  {paragraph}
                </p>
              ))}
              <img
                className="w-full my-3 lg:hidden"
                src="/img/lastFight/Self_mobile.jpg"
              />
            </div>
            <div>
              <div className="float-right flex-col hidden lg:block">
                <img
                  className="w-80 h-full mb-3 ml-5"
                  src="/img/lastFight/Self.jpg"
                />
                <img
                  className="w-80 h-full mb-3 ml-5"
                  src="/img/lastFight/WithGun.jpg"
                />
              </div>
              {lastFightThirdParagraphs.map((paragraph, index) => (
                <p
                  key={index}
                  className="text-justify text-base mb-2 lg:text-xl lg:mb-4"
                >
                  {paragraph}
                </p>
              ))}
              <img
                className="w-full my-3 lg:hidden"
                src="/img/lastFight/WithGun.jpg"
              />
            </div>
            <p className="ml-10 mb-3 text-end text-lg font-boldTitle lg:text-xl ">
              - УРИВОК ІЗ ДРУГОГО ВИПУСКУ ЖУРНАЛУ "ВІСТНИК АКС"
            </p>
          </div>
        ) : (
          <div className="flex">
            <p className="text-justify text-base mb-2 lg:hidden">
              {text.lastFightFirst}
            </p>
            <div>
              <p className="text-justify text-xl mb-4 hidden lg:block">
                <img
                  className="w-[305px] h-[585px] float-left mr-5"
                  src="/img/lastFight/MaxBig.jpg"
                />
                {text.lastFightFirst}
              </p>
              <p className="text-justify text-xl mb-4 hidden lg:block">
                {text.lastFightSecond.slice(0, 1150)}
                ...
              </p>
            </div>
          </div>
        )}
      </CSSTransition>

      <div
        className="flex justify-end items-center cursor-pointer"
        onClick={toggleText}
      >
        <div className="w-3 h-3 mr-4">
          <img src="/img/VectorBlack.svg" alt="Expand" />
        </div>
        <p className=" text-lg font-boldTitle">
          {showFullText ? "ЗГОРНУТИ" : "ЧИТАТИ ДАЛІ"}
        </p>
      </div>
    </div>
  );
};
