import React from "react";

export const Footer = () => {
  return (
    <div className="py-5 mx-5 bg-white lg:px-48 lg:py-12">
      <div className="flex justify-between">
        <div className="flex items-center">
          <div>
            <img src="/img/LogoBlack.svg" className="w-11 h-full lg:w-24" />
          </div>
          <p className="text-black ml-4 mt-3 lg:mt-0 text-base font-boldTitle lg:text-5xl">
            АВАНГАРД "АКС"
          </p>
        </div>
        <div className="flex items-center">
          <a href="https://instagram.com/avantguardia_2.0" target="_blank">
            <img className="w-5 lg:w-7" src="/img/links/Instagram.svg" />
          </a>
          <a
            href="https://youtube.com/@user-dl2ul4dh5d?si=r3HlL3omvPTWf0tR"
            target="_blank"
          >
            <img
              className="w-5 ml-5 lg:w-7 lg:ml-7"
              src="/img/links/Youtube.svg"
            />
          </a>
          <a href="https://t.me/avantguardia" target="_blank">
            <img
              className="w-5 ml-5 lg:w-7 lg:ml-7"
              src="/img/links/Telegram.svg"
            />
          </a>
        </div>
      </div>
    </div>
  );
};
