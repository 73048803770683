import React from "react";
import { text } from "../../text/text";

export const Immortalization = () => {
  const immortalizationParagraphs = text.immortalizatoin.split("\n");
  return (
    <div className="p-5 bg-black lg:p-48 border-b-2 border-white">
      <div className="flex mb-6">
        <img
          className="lg:w-[66px] lg:h-[66px] lg:mt-1 h-8"
          src="/img/Immortalization.svg"
          alt="Biography"
        />
        <p
          lang="uk-UA"
          className="text-mint ml-3 text-4xl font-boldTitle lg:text-8xl w-[75vw]"
          style={{ overflowWrap: "break-word" }}
        >
          УВІКОВІ&shy;ЧЕ&shy;ННЯ
        </p>
      </div>
      {immortalizationParagraphs.map((paragraph, index) => (
        <p
          key={index}
          className="text-justify text-base mb-4 lg:text-xl lg:mb-4 text-white"
        >
          {paragraph}
        </p>
      ))}
      <div className="flex lg:justify-center lg:flex-row flex-col lg:mt-10 ">
        <img
          className="w-full lg:w-[350px] h-full mr-20"
          src="/img/Immortalization/Tanya.jpg"
          alt="Immortalization"
        />
        <img
          className="w-full lg:w-[350px] h-full mt-4 lg:mt-0"
          src="/img/Immortalization/Azov.jpg"
          alt="Immortalization"
        />
      </div>
      <div className="flex justify-center">
        <img
          className="w-full lg:w-[780px] h-full lg:mt-20 mt-4"
          src="/img/Immortalization/Tanya2.jpg"
          alt="Immortalization"
        />
      </div>
    </div>
  );
};
